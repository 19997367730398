export const bannerObjOne = {
  darkTop: true,
  large: true,
  id: 'home',
  video: true,
  mediaSrc: require('../../videos/arm.mp4').default,
  headline: 'We are a Nonprofit Aiming to Change the World in Brianna\'s Vision.',
  description: 'Donate to help make the world a better place.',
  haveButton: true,
  route: 'https://donate.stripe.com/14kg0A8mWcFqesg8ww',
  buttonLabel: "Donate",
  fade: true
}

export const bannerObjTwo = {
  darkTop: false,
  large: false,
  id: 'hands',
  video: false,
  mediaSrc: require('../../images/bk.jpeg').default,
  headline: '',
  description: '',
  haveButton: true,
  route: 'https://donate.stripe.com/14kg0A8mWcFqesg8ww',
  buttonLabel: '',
  fade: true
}

export const bannerObjThree = {
  darkTop: true,
  large: false,
  id: 'brisStory',
  video: false,
  mediaSrc: require('../../images/bk.jpeg').default,
  headline: '',
  description: '',
  haveButton: false,
  route: '',
  buttonLabel: 'Return To Site',
  fade: true
}

export const bannerObjFour = {
  darkTop: false,
  large: false,
  id: 'brisStory',
  video: false,
  mediaSrc: require('../../images/bk.jpeg').default,
  headline: '',
  description: '',
  haveButton: true,
  route: '',
  buttonLabel: '>',
  fade: true
}

export const bannerObjFive = {
  darkTop: true,
  large: true,
  id: 'shop',
  video: true,
  mediaSrc: require('../../videos/shop.mp4').default,
  headline: 'Shop Coming Soon',
  description: '',
  haveButton: true,
  route: '',
  buttonLabel: 'Return To Site',
  fade: true
}


export const bannerObjSixA = {
  darkTop: true,
  large: true,
  id: 'run',
  video: false,
  mediaSrc: require('../../images/srfb1.jpeg').default,
  headline: '',
  description: '',
  haveButton: false,
  route: '',
  buttonLabel: 'Return To Site',
  fade: false
}

export const bannerObjSixB = {
  darkTop: false,
  large: true,
  id: 'run',
  video: false,
  mediaSrc: require('../../images/srfb2.jpeg').default,
  headline: '',
  description: '',
  haveButton: false,
  route: '',
  buttonLabel: 'Return To Site',
  fade: false
}

export const bannerObjSixC = {
  darkTop: false,
  large: true,
  id: 'run',
  video: false,
  mediaSrc: require('../../images/srfb3.jpeg').default,
  headline: '',
  description: '',
  haveButton: false,
  route: '',
  buttonLabel: 'Return To Site',
  fade: false
}
