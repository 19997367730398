export const homeObjOneA = {
  id: 'brisStory',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Bri\'s Story by Mikaela Kupfer',
  headline: '',
  description: "Just 24 years old, Brianna was already one of the most courageous, kind, and loving people you would ever meet. She was always trying to avoid the spotlight, yet it always seemed to find her, simply because she radiated such goodness and love. Brianna made an impact on everyone she interacted with; they were met with beauty and grace that was unmatched. Brianna was all good. This is not something you can say about many people on this earth, and it made her into the pure person she was. Brianna’s caring, compassionate nature led her to champion causes that promoted the betterment of people, society, and the environment alike. She cared about people and the planet equally. She was a member of her neighborhood sustainability group, Resilient Palisades, and was always seeking out ways and spreading awareness to her friends about how to live more sustainably. Brianna was inquisitive and always looking to better herself and learn whenever and however she could. She was always creating lists of things she wanted to learn, places she wanted to see, books she wanted to read, and taking notes on historical events she wanted to learn more about.",
  buttonLabel: 'Donate',
  route: 'https://donate.stripe.com/14kg0A8mWcFqesg8ww',
  imgStart: true,
  img: require('../../images/book.svg').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: false
}
export const homeObjOneB = {
  id: 'brisStory',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: '',
  description: "From a very young age, Brianna was an avid reader, staying up until all hours of the night to finish her book while everyone else was sound asleep. She was always found with a book or two in tow. If you needed a book recommendation, Brianna was the one to ask. If you needed someone to get as excited as you were to learn about something, Brianna was the one to go to. She lived life with big eyes and even bigger ears, the most observant and understanding person. This is what made Brianna such an incredible friend, daughter, and sister. On top of her kind and seemingly reserved demeanor, Brianna was adventurous and daring. She loved life and lived it fearlessly and fiercely. She was always pushing everyone to be more courageous and brave, insisting life was more fun if you lived it with a little less hesitation. She loved traveling, and would often draw out maps and keep notes of all the places she had visited and wanted to visit. Brianna loved to make life more interesting and go on adventures whenever she could, whether it be a drive with the windows down playing her favorite music, convincing her sister who is afraid of heights to go skydiving in Australia, car camping in national parks, or staying in a tiny home in the wilderness of Costa Rica for the night. No matter what Bri was doing, she did it with a contagious smile and free spirit that made everyone feel safe and excited. She lived a very active lifestyle and loved yoga, running, boxing, and dance. No matter what activity, she moved in a way that outshone everyone around her without any effort.",
  buttonLabel: 'Donate',
  route: 'https://donate.stripe.com/14kg0A8mWcFqesg8ww',
  imgStart: false,
  img: require('../../images/scene.svg').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: false
}
export const homeObjOneC = {
  id: 'brisStory',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: '',
  description: "She was always scampering up rock formations so fast, or putting herself upside down in handstand positions, or kicking her leg so high in boxing; everyone was always in awe of her. Brianna excelled at everything she did, but she was modest in all aspects of her life. Where she truly came into herself was through her creativity. Brianna was always surprising people by designing her own clothing from scratch, or upcycling her mom’s old clothing into something that was unique and on trend. Something that was effortless to her, looked so intricate and detailed, it was hard to believe she had created it with her own two hands and imagination. Brianna’s dream was to create her own clothing line someday and was always sketching new ideas, creating outfits others were too afraid to wear, and rocking every style. In her last few months of her life, Brianna was always seen sporting her favorite knee high white cowboy boots. Brianna studied design in graduate school and worked at a sustainable interior design store, Croft House. She always had an eye for design and was the one you would go to for any creative endeavor. She single handedly decorated her room, her sister’s room, and didn’t hold back with her ideas on how to redecorate her family home. Above all, Brianna was truly a role model for caring and for love. She loved deeply and strongly and put others before her. Brianna was an angel on and off this earth. It’s hard to find people like Brianna, who truly represent the good in this world.",
  buttonLabel: 'Donate',
  route: 'https://donate.stripe.com/14kg0A8mWcFqesg8ww',
  imgStart: true,
  img: require('../../images/run.svg').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: false
}
export const homeObjOneD = {
  id: 'brisStory',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: '',
  description: "On January 13th, our beautiful Brianna was taken from us in a senseless and horrific act. Brianna, while working alone at Croft House, was stabbed to death. It’s hard to live in a world where evil wins over good, but this foundation is meant to serve as a representation of the world Brianna deserved and wanted. We will be championing causes dear to Brianna, such as environmental and social justice issues, as well as honoring victims like Brianna through the protection of women and social betterment. We believe that Brianna was and continues to be a guiding light of pure love and that with more love in this world, we can transform it into one she would be proud of. It starts with the family and with the community, and if we love each other and this earth as fiercely as Brianna did, we can mold this world into a better place. Hold your loved ones tight and live wild like Brianna would encourage you to live.",
  buttonLabel: 'Donate',
  route: 'https://donate.stripe.com/14kg0A8mWcFqesg8ww',
  imgStart: false,
  img: require('../../images/flowers.svg').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: false
}



export const homeObjTwo = {
  id: 'ourImpact',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Our Impact',
  headline: 'The Brianna Kupfer Foundation',
  description: "Through its charitable giving, the foundation intends to improve conditions for mankind by supporting sustainability, resiliency, social and environmental betterment, and the protection and empowerment of women.",
  buttonLabel: 'Donate',
  route: 'https://donate.stripe.com/14kg0A8mWcFqesg8ww',
  imgStart: false,
  img: require('../../images/scene.svg').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: false
}

export const homeObjThree =  {
  id: 'events',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Donations by Wire Transfer',
  headline: 'First Republic Bank - 111 Pine Street - San Fransisco, CA 94111 - ABA/Routing # 321 081 669 - Credit to Acct # 80010716925',
  description: 'For credit card donations, see our donation portal',
  buttonLabel: 'Donation Portal',
  route: 'https://donate.stripe.com/14kg0A8mWcFqesg8ww',
  imgStart: true,
  img: require('../../images/run.svg').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: false
}

export const eventObj = {
  id: 'waysToGive',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Check Donations',
  headline: '',
  description: 'The Brianna Kupfer Foundation - 15332 Antioch Street #354 - Pacific Palisades, CA 90272. For credit card donations, see our donation portal',
  buttonLabel: 'Donation Portal',
  route: 'https://donate.stripe.com/14kg0A8mWcFqesg8ww',
  imgStart: false,
  img: require('../../images/flowers.svg').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: false
}
