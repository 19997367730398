import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import {IconContext} from 'react-icons/lib';
import {animateScroll as scroll} from 'react-scroll';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLink,
  NavHardLink,
  NavBtn,
  NavBtnLink,
  Logo,
  LogoBG
} from './NavbarElements';

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  //const {pathname} = useLocation();

/*<NavItem>
  <NavLink to='ourImpact'
    smooth={true}
    duration={500}
    spy={true}
    exact='true'
    offset={-80}
  >Our Impact</NavLink>
</NavItem>
<NavItem>
  <NavLink to='events'
  smooth={true}
  duration={500}
  spy={true}
  exact='true'
  offset={-80}
  >Events</NavLink>
</NavItem>
<NavItem>
  <NavLink to='waysToGive'
  smooth={true}
  duration={500}
  spy={true}
  exact='true'
  offset={-80}
  >Ways To Give</NavLink>
</NavItem>
  */

  const changeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
    <IconContext.Provider value={{ color : '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <MobileIcon onClick={toggle} >
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavHardLink to='/brisStory'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >Bri's Story</NavHardLink>
              </NavItem>
            </NavMenu>
            <NavLogo to='/' onClick={toggleHome}>
              <LogoBG src={require('../../images/bkw.png').default} />
            </NavLogo>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
