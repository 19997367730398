import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';


export const Nav = styled.nav`
  height: 160px;
  margin-top: -160px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 5;

  &:before {
    background: ${({scrollNav}) => (scrollNav ? '#F362743' : 'transparent')};

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2),
      rgba(255,207,235, 1) 100%
    );
    opacity: ${({ scrollNav }) => (scrollNav ? 1 : 0)};
    transition: opacity 0.2s ease;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -4px; // Adjust as needed
    left: 0;
    right: 0;
    height: 4px; // Adjust the thickness of the bar
    background-color: white;
    transform: scaleX(${({ scrollNav }) => (scrollNav ? 1 : 0)});
    transform-origin: left;
    transition: transform 0.8s ease 0.1s; // Starts after the background transition ends
    z-index: -1;
  }


  @media screen and (max-width: 960px) {
    transition: .8s all ease;
  }
`

export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px:
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`

export const Logo = styled.img`
  width: 100%;
  height: 160px;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 0;
  display: block;
  position: absolute;

`

export const LogoBG = styled.img`
  width: 100%;
  height: 160px;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
  display: block;
`

export const NavLogo = styled(LinkR)`
  color: #ffcfeb;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1rem;
  align-items: center;
  text-align: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;

  position: relative;
  display: inline-block;
  z-index: 999;

  &:hover {
    color: #fff;
    transition: 0.3s ease-out
  }
`

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 30%;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
    z-index: 1000;
  }
`
export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavItem = styled.li`
  height: 80px;
`

export const NavLink = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 2rem;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: #d3e6ff;
    transition: 0.3s ease-out
  }

  &.active {
    border-bottom: 3px solid #ffcfeb;
  }
`

export const NavHardLink = styled(LinkR)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  padding: 0 auto;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: #d3e6ff;
    transition: 0.3s ease-out
  }

  &:active {
    border-bottom: 3px solid #ffcfeb;
  }
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #F362743;
  white-space: nowrap;
  padding: 10px 22px;
  color: #FFF;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.3s ease-in-out;
    background: #fff;
    color: #70808f;
  }
`
