import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages';
import BriStoryPage from './pages/BriStoryPage';
import AboutPage from './pages/AboutPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/brisStory" component={BriStoryPage} exact />
        <Route path="/about" component={AboutPage} exact />
        <Route path="/success" component={Home} exact />
      </Switch>
    </Router>
  );
}

export default App;
